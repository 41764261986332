<template>
  <span v-if="!currentURLLang">
    <Lang
      :by-key="byKey"
      :by-raw="byRaw"
      :capitalize-first="capitalizeFirst"
      :capitalize-all="capitalizeAll"
      :plural="plural"
      :params="params"
      :default-label="defaultLabel"
      :trim-start="trimStart"
      :trim-end="trimEnd"
      :trim="trim"
    />
  </span>

  <span v-else>
    {{ text }}
  </span>
</template>

<script lang="ts">
import { useLangSelector } from '~/core/composable/shared/useLangSelector'

import { translateForLang, translateByKeySilentForLang } from '~/core/ts/util/translate'
import { capitalizeFirstWord, capitalizeAllWords } from '~/core/ts/util/string'

export default defineNuxtComponent({
  props: {
    byKey: {
      type: String,
      default: () => '',
    },
    byRaw: {
      type: Object,
      default: () => null,
    },
    keyForRaw: {
      type: String,
      default: () => '',
    },
    defaultLabel: {
      type: String,
      default: () => 'NOTEXT',
    },
    capitalizeFirst: {
      type: Boolean,
      default: false
    },
    capitalizeAll: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    plural: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object as PropType<Record<string, string | number>>,
      default: () => ({})
    },
    trimStart: {
      type: Boolean,
      default: false
    },
    trimEnd: {
      type: Boolean,
      default: false
    },
    trim: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const {
      currentURLLang,
    } = useLangSelector()

    const text = computed(() => {
      let t = ''

      if (!currentURLLang) {
        return ''
      }

      if (props.byKey) {
        t = translateByKeySilentForLang(props.byKey, currentURLLang, props.plural) || props.defaultLabel
      } else if (props.byRaw) {
        t = props.keyForRaw
          ? translateForLang(props.byRaw, currentURLLang)[props.keyForRaw] || props.defaultLabel
          : translateForLang(props.byRaw, currentURLLang)
      }

      Object.entries(props.params).forEach(([key, value]) => {
        t = t.replaceAll(`{{${key}}}`, value + '')
      })

      if (props.capitalizeFirst) {
        t = capitalizeFirstWord(t)
      }

      if (props.capitalizeAll) {
        t = capitalizeAllWords(t)
      }

      if (props.uppercase) {
        t = t.toUpperCase()
      }

      if (props.trimStart) {
        t = t.trimStart()
      }
      if (props.trimEnd) {
        t = t.trimEnd()
      }
      if (props.trim) {
        t = t.trim()
      }

      return t
    })

    return {
      currentURLLang,
      text,
    }
  }
})
</script>
